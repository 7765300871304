import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { GlobalState } from 'store';

import { IPaymentMethod } from 'models/billing';
import { getPaymentMethods } from 'api/paymentmethods';

export type PaymentMethodsState = {
    isFetching: boolean;
    data: IPaymentMethod[];
};

const initialPaymentMethodsState: PaymentMethodsState = {
    isFetching: false,
    data: [],
};

export const fetchPaymentMethodsAsync = createAsyncThunk(
    'paymentMethods/fetch',
    async () => {
        return await getPaymentMethods();
    },
    {
        condition: (arg: void, { getState }) => {
            const { paymentMethods } = getState() as GlobalState;
            if (paymentMethods.isFetching) {
                return false;
            }
        },
    }
);

export const paymentMethodsSlice = createSlice({
    name: 'paymentMethods',
    initialState: initialPaymentMethodsState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPaymentMethodsAsync.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchPaymentMethodsAsync.fulfilled, (state, action) => {
                state.isFetching = false;
                state.data = action.payload;
            })
            .addCase(fetchPaymentMethodsAsync.rejected, (state) => {
                state.isFetching = false;
            });
    },
});

//#region selectors
export const selectPaymentMethods = (state: GlobalState) => state.paymentMethods.data;
//#endregion selctors

export default paymentMethodsSlice.reducer;
