import React, { useState, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { AiOutlineAudio, AiOutlineCreditCard, AiOutlineHome, AiOutlineLogout, AiOutlineQuestionCircle, AiOutlineUser } from 'react-icons/ai';
import Modal from 'react-bootstrap/Modal';

import { useTranslation, getI18n } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks';
import { fetchDashboardDataAsync, selectData } from 'store/slices/dashboard';

import { SimpleCurrency } from 'utils/numbers';

interface ISidebarProps {
    visible: boolean;
    onClose: () => void;
}

export const SidebarMenu: React.FC<ISidebarProps> = (props: ISidebarProps) => {
    const { t } = useTranslation();
    const data = useAppSelector(selectData);
    const dispatch = useAppDispatch();
    const [isEnglish, setIsEnglish] = useState(getI18n().language.includes('en'));

    if (!data) {
        dispatch(fetchDashboardDataAsync());
    }

    const switchLanguage = useCallback(() => {
        getI18n()
            .changeLanguage(isEnglish ? 'es' : 'en')
            .then(() => {
                setIsEnglish(!isEnglish);
            });
    }, [isEnglish]);

    return (
        <Modal dialogClassName="side-modal-dialog" show={props.visible} onHide={props.onClose}>
            <Modal.Header closeButton>
                <div className="sidebar-profile-info">
                    <div className="sidebar-profile-thumb">
                        <AiOutlineUser />
                    </div>
                    <div className="sidebar-profile-text">
                        <h3 title={ t('Your account name') || 'Your account name' }>{ data && data.client ? data.client.name : '-'}</h3>
                        <p title={ t('Account Number') || 'Account Number' }>{ data && data.client ? data.client.accountNumber : '-' }</p>
                    </div>
                </div>
            </Modal.Header>
            <div className="sidebar-profile-wallet">
                <div className="add-card-info">
                    <p>{ t('Balance Due') }</p>
                    <h3 title={ t('Balance Due') || 'Balance Due' }><SimpleCurrency amount={data ? data.balanceDue : ''} /></h3>
                </div>
            </div>
            <Modal.Body>
                <div className="sidebar-nav">
                    <div className="sidebar-nav-item">
                        <h3>{ t('Menu') }</h3>
                        <ul className="sidebar-nav-list">
                            <li title="link to the home page"><NavLink to="/" exact><AiOutlineHome /> { t('Home') }</NavLink></li>
                            <li title="link to your account information"><NavLink to="/account" exact><AiOutlineUser /> { t('Account') }</NavLink></li>
                            { data && !data.org.onlinePaymentsDisabled ? <li title={ t('link to manage payment methods') || 'link to manage payment methods' }><NavLink to="/payment-methods" exact><AiOutlineCreditCard /> { t('Payment Methods') }</NavLink></li> : null }
                            <li title={ t('help pages & documentation') || 'help pages & documentation'}><a href="https://lendiom.com/docs/pay/" target="_blank" rel="noreferrer"><AiOutlineQuestionCircle /> { t('Help') }</a></li>
                            <li title={ (isEnglish ? t('switch to spanish') : t('switch to english')) || 'switch language' } className="pointer" onClick={switchLanguage}><span><AiOutlineAudio /> { isEnglish ? t('Spanish') : t('English') }</span></li>
                            <li title={ t('logout of Lendiom Pay') || 'logout of Lendiom Pay' }><NavLink to="/auth/logout"><AiOutlineLogout /> { t('Logout') }</NavLink></li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
