import React from 'react';
import Button from 'react-bootstrap/Button';
import { MdOutlineArrowDropDownCircle } from 'react-icons/md';

interface IPageBodyHeaderDropdownButtonProps {
    onClick: (e: any) => void;
}

export const PageBodyHeaderDropdownButton = React.forwardRef<HTMLDivElement, IPageBodyHeaderDropdownButtonProps>(({ children, onClick }, ref) => (
    <div
        className={`color-blue`}
        ref={ref}
        onClick={(e: any) => {
            e.preventDefault();

            onClick(e);
        }}
    >
        <Button variant="link"><MdOutlineArrowDropDownCircle /></Button>
        <p className="pointer">{ children }</p>
    </div>
));
