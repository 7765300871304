import React, { Suspense, lazy } from 'react';
import { withRouter, RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';
import Loader from 'react-loader-spinner';

import { Navbar } from 'layout/components/navbar';

import { hasValidToken } from 'utils/auth';

import { StripeLoader } from './stripe';

import { NotFoundView } from 'views/404';
import { DashboardView } from 'views/dashboard';
import { PaymentMethodsView } from 'views/payment-methods';

const LoanView = lazy(() => import('views/loan'));
const LoanDownPaymentView = lazy(() => import('views/loan/downPayment'));
const LoanPaymentView = lazy(() => import('views/loan/payment'));
const RentalView = lazy(() => import('views/rental'));
const AccountView = lazy(() => import('views/account'));

interface IMainLayoutProps extends RouteComponentProps {}

interface IMainLayoutState {
    loading: boolean;
}

class MainLayoutBase extends React.PureComponent<IMainLayoutProps, IMainLayoutState> {
    state: Readonly<IMainLayoutState> = {
        loading: true,
    };

    async componentDidMount() {
        if (!await hasValidToken()) {
            console.log('client does not have a valid token, redirecting them to the login')
            this.props.history.push('/auth/login');
            return;
        } else {
            this.setState({ loading: false });
        }
    }

    get loader() {
        return (
            <div className="loader-overlay">
                <Loader type="Oval" color="#3646cd" />
            </div>
        );
    }

    render() {
        if (this.state.loading) {
            return this.loader;
        }

        return (
            <StripeLoader>
                <Navbar showBackButton={this.props.location.pathname !== '/'} />

                <Switch>
                    <Redirect from="/loans" to="/" />

                    <Route exact path="/" component={DashboardView} />
                    <Route exact path="/account" render={() => <Suspense fallback={null}><AccountView /></Suspense>} />
                    <Route exact path="/loan/:loanId?" render={() => <Suspense fallback={null}><LoanView /></Suspense>} />
                    <Route exact path="/loan/:loanId/down-payment" render={() => <Suspense fallback={null}><LoanDownPaymentView /></Suspense>} />
                    <Route exact path="/loan/:loanId/payment" render={() => <Suspense fallback={null}><LoanPaymentView /></Suspense>} />
                    <Route exact path="/rental/:rentalId?" render={() => <Suspense fallback={null}><RentalView /></Suspense>} />
                    <Route exact path="/payment-methods" component={PaymentMethodsView} />

                    <Route path="*"><NotFoundView /></Route>
                </Switch>
            </StripeLoader>
        );
    }
}

export const MainLayout = withRouter(MainLayoutBase);
