import React from 'react';
import { Link } from 'react-router-dom';

//#region list item
interface IPageBodyListItemProps {
    linksTo?: string | (() => void);
    thumb: React.ReactNode;
    title: React.ReactNode;
    description: React.ReactNode;
    detailsRed?: boolean;
    details: React.ReactNode;
}

interface IPageBodyListItemState {}

export class PageBodyListItem extends React.PureComponent<IPageBodyListItemProps, IPageBodyListItemState> {
    state: Readonly<IPageBodyListItemState> = {};

    get content() {
        return (
            <React.Fragment>
                <div className="transaction-card-info">
                    <div className="transaction-info-thumb">
                        { this.props.thumb }
                    </div>
                    <div className="transaction-info-text">
                        <h3>{ this.props.title }</h3>
                        <p>{ this.props.description }</p>
                    </div>
                </div>
                <div className={`transaction-card-det ${ this.props.detailsRed ? 'negative-number' : '' }`}>
                    { this.props.details }
                </div>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div className="transaction-card mb-15">
                { typeof this.props.linksTo === 'string' ?
                    <Link to={this.props.linksTo}>
                        { this.content }
                    </Link>
                :
                    <div className="container" onClick={this.props.linksTo}>
                        { this.content }
                    </div>
                }
            </div>
        );
    }
}
//#endregion list item

interface IPageBodyListProps {
    title?: React.ReactNode;
    items: React.ReactElement<PageBodyListItem>[];
    emptyContent: React.ReactNode;
}

interface IPageBodyListState {}

export class PageBodyList extends React.PureComponent<IPageBodyListProps, IPageBodyListState> {
    static Item = PageBodyListItem;

    state: Readonly<IPageBodyListState> = {};

    get items() {
        if (this.props.items.length === 0) {
            return this.props.emptyContent;
        }

        return this.props.items;
    }

    render() {
        return (
            <div className="transaction-section pb-15">
                <div className="section-header">
                    { this.props.title ? <h2>{ this.props.title }</h2> : null }
                </div>

                { this.items }
                { this.props.children }
            </div>
        );
    }
}
