import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { AiOutlineRollback, AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';

import { SidebarMenu } from 'layout/components/sidebar';
import { isImpersonating } from 'utils/auth';

interface INavbarProps extends RouteComponentProps {
    showBackButton: boolean;
}

interface INavbarState {
    isImpersonating: boolean;
    scrolled: boolean;
    sidebarOpen: boolean;
}

class NavbarBase extends React.PureComponent<INavbarProps, INavbarState> {
    state: Readonly<INavbarState> = {
        isImpersonating: false,
        scrolled: window.scrollY > 10,
        sidebarOpen: false,
    };

    componentDidMount() {
        window.addEventListener('scroll', this.scrollHandler);
        isImpersonating().then((is) => this.setState({ isImpersonating: is }));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollHandler);
    }

    scrollHandler = () => {
        this.setState({ scrolled: window.scrollY > 10 });
    }

    toggleSidebarMenu = () => {
        this.setState({ sidebarOpen: !this.state.sidebarOpen });
    }

    onBackButtonClick = () => {
        if (this.props.history.length > 1) {
            this.props.history.goBack();
        } else {
            this.props.history.push('/');
        }
    }

    get backButton() {
        if (!this.props.showBackButton) {
            return null;
        }

        return (
            <div className="appbar-action-item">
                <button className="appbar-action-bar" onClick={this.onBackButtonClick}><AiOutlineRollback /></button>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <div className={`fixed-top ${ this.state.scrolled ? 'non-fixed' : '' } ${ this.state.isImpersonating ? 'impersonating-fix' : ''}`}>
                    <div className={`appbar-area sticky-black ${ this.state.scrolled ? 'is-sticky' : '' }`}>
                        <div className="container">
                            <div className="appbar-container">
                                <div className="appbar-item appbar-actions">
                                    { this.backButton }

                                    <div className="appbar-action-item">
                                        <button className="appbar-action-bar" onClick={this.toggleSidebarMenu}>
                                            { this.state.sidebarOpen ? <AiOutlineMenuFold /> : <AiOutlineMenuUnfold /> }
                                        </button>
                                    </div>
                                </div>
                                <div className="appbar-item appbar-brand me-auto">
                                    <Link to="/">
                                        Lendiom Pay
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SidebarMenu visible={this.state.sidebarOpen} onClose={this.toggleSidebarMenu} />
            </React.Fragment>
        );
    }
}

export const Navbar = withRouter(NavbarBase);
