import React from 'react';
import { withRouter, RouteComponentProps, Switch, Redirect, Route } from 'react-router-dom';

import { NotFoundView } from 'views/404';
import { LoginView } from 'views/auth/login';
import { LogoutView } from 'views/auth/logout';

interface ILayoutForNoAuthorizationProps extends RouteComponentProps {}

interface ILayoutForNoAuthorizationState {}

class LayoutForNoAuthorizationBase extends React.PureComponent<ILayoutForNoAuthorizationProps, ILayoutForNoAuthorizationState> {
    state: Readonly<ILayoutForNoAuthorizationState> = {};

    render() {
        return (
            <Switch>
                <Redirect exact from="/auth" to="/auth/login" />

                <Route exact path="/auth/login/:accountNumber?" component={LoginView} />
                <Route exact path="/auth/logout" component={LogoutView} />

                <Route path="*"><NotFoundView /></Route>
            </Switch>
        );
    }
}

export const LayoutForNoAuthorization = withRouter(LayoutForNoAuthorizationBase);
