import React from 'react';
import { format, parseJSON } from 'date-fns';

export const SimpleDate: React.FunctionComponent<{ date: string, simplier?: boolean }> = (props) => {
    let simplier = false;
    if (props.simplier) {
        simplier = true;
    }

    return (
        <span>{ formatSimpleDate(props.date, simplier) }</span>
    );
}

export const formatSimpleDate = (date: string, simplier?: boolean): string => {
    if (!date || date.startsWith('0001-01-01')) {
        return '-';
    }

    const d = parseJSON(date);

    if (d.getFullYear() === 1900) {
        return '-';
    }

    return format(d, simplier ? 'M/d/yy' : 'MM/dd/yyyy');
}
