import { toast } from 'react-toastify';
import { ErrorResponse } from 'models/errors/errorResponse';

export function displayErrorNotification(e: any) {
    console.error('Displayed the error notification:', e);

    if (typeof e === 'undefined') {
        toast.error('Unknown error 🤔');
    } else if (typeof e === 'string') {
        toast.error(e);
    } else if (typeof e === 'number') {
        toast.error(`Unknown error with a status code of: ${ e }`);
    } else if (typeof e.message === 'undefined') {
        toast.error(`${ e.error } (${ e.code })`);
    } else if (typeof e.message === 'string' && e.message.includes('Failed to fetch')) {
        toast.error('Failed to talk to our servers. Please try again.');
    } else if (e instanceof ErrorResponse && e.status === 401) {
        console.log('not authenticated result');
    } else {
        toast.error(e.message);
    }
}
