import React, { lazy, Suspense } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { AiOutlineBank, AiOutlineCreditCard,  } from 'react-icons/ai';

import { PageBody } from 'layout/components/pageBody';

import { IPaymentMethod } from 'models/billing';
import { displayErrorNotification } from 'utils/errors';
import { timeoutToPromise } from 'utils/promises';
import { showConfirmModal } from 'utils/modals';

import { AppDispatch, GlobalState } from 'store';
import { fetchDashboardDataAsync } from 'store/slices/dashboard';

import { getPaymentMethods, getPlaidLinkToken, removePaymentMethod, setDefaultPaymentMethod } from 'api/paymentmethods';
import { trackUmami } from 'utils/umami';

const PlaidAchModal = lazy(() => import('./plaidAchModal'));
const StripeCardModal = lazy(() => import('./stripeCardModal'));
const PayArcCardModal = lazy(() => import('./payArcCardModal'));
const PayArcBankModal = lazy(() => import('./payArcBankModal'));

const mapState = (state: GlobalState) => ({
    dashboardData: state.dashboard.data,
});

const connector = connect(mapState);

interface IPaymentMethodsProps extends ConnectedProps<typeof connector>, WithTranslation { }

interface IPaymentMethodsState {
    loadingInitialMethods: boolean;
    cardModalVisible: boolean;
    loadingBank: boolean;
    achModalVisible: boolean;
    plaidLinkToken: string;
    paymentMethods: IPaymentMethod[];
}

class PaymentMethodsViewBase extends React.PureComponent<IPaymentMethodsProps, IPaymentMethodsState> {
    state: Readonly<IPaymentMethodsState> = {
        loadingInitialMethods: true,
        cardModalVisible: false,
        loadingBank: false,
        achModalVisible: false,
        plaidLinkToken: '',
        paymentMethods: [],
    };

    componentDidMount() {
        this.loadPaymentMethods();
    }

    loadPaymentMethods = async () => {
        try {
            (this.props.dispatch as AppDispatch)(fetchDashboardDataAsync());
            const methods = await getPaymentMethods();

            this.setState({ paymentMethods: methods, loadingInitialMethods: false });
        } catch (e) {
            displayErrorNotification(e);
        }
    }

    openCardModal = () => {
        this.setState({ cardModalVisible: true });
    }

    closeCardModal = async (created: boolean) => {
        if (created) {
            await timeoutToPromise(2500);
            await this.loadPaymentMethods();
        }

        this.setState({ cardModalVisible: false });
    }

    onLinkBankClick = () => {
        if (this.props?.dashboardData?.org.paymentProvider === 'payArc') {
            this.setState({ loadingBank: true, achModalVisible: true });
            return;
        }

        this.setState({ loadingBank: true }, async () => {
            try {
                const res = await getPlaidLinkToken();

                this.setState({ plaidLinkToken: res.linkToken, achModalVisible: true });
            } catch (e) {
                displayErrorNotification(e);
                this.setState({ loadingBank: false });
            }
        });
    }

    onClosePlaidModal = async (created: boolean) => {
        if (created) {
            await timeoutToPromise(2500);
            await this.loadPaymentMethods();
        }

        this.setState({ achModalVisible: false, loadingBank: false });
    }

    get onlinePaymentsNotSetup() {
        let orgName = 'n/a';
        if (this.props.dashboardData?.org?.name) {
            orgName = this.props.dashboardData.org.name;
        }

        return (
            <Alert variant="info">
                <Alert.Heading>{ this.props.t('Online Payments Not Set Up') }</Alert.Heading>
                <hr />
                <p>
                    <Trans i18nKey="Online Payments Not Set Up Paragraph">
                        Online payments are not enabled. Please contact <strong>{{ orgName }}</strong> to make a payment.
                    </Trans>
                </p>
            </Alert>
        );
    }

    get noPaymentMethodsContent() {
        if (this.state.loadingInitialMethods) {
            return (
                <div className="loader-overlay">
                    <Loader type="Oval" color="#3646cd" />
                </div>
            );
        }

        const { dashboardData } = this.props;
        if (dashboardData && !dashboardData.client.canSetupPaymentMethods) {
            const orgName = dashboardData.org.name;

            return (
                <Alert variant="warning">
                    <Alert.Heading>{ this.props.t('Account Not Setup') }</Alert.Heading>
                    <p>
                        <Trans i18nKey="Account Not Setup Details">
                            Adding payment methods requires an email address and a physical mailing address. Your account does not have one of these. Please contact <strong>{{ orgName }}</strong> to provide them this information.
                        </Trans>
                    </p>
                </Alert>
            );
        }

        return (
            <Alert variant="info">
                <Alert.Heading>{ this.props.t('No Payment Methods') }</Alert.Heading>
                <p>{ this.props.t('No payment methods details bankd and card support') }</p>
                <hr />
                <div className="d-flex justify-content-end">
                    { dashboardData?.client.allowedPaymentMethods.banks && dashboardData?.org.paymentProvider === 'stripe' ? <Button onClick={this.onLinkBankClick} variant="outline-primary" style={{ marginRight: '15px' }}>{ this.state.loadingBank ? <Spinner size="sm" animation="border" /> : <AiOutlineBank /> } { this.props.t('Link Bank') }</Button> : null }
                    { dashboardData?.client.allowedPaymentMethods.cards ? <Button onClick={this.openCardModal} variant="outline-success"><AiOutlineCreditCard /> { this.props.t('New Card') }</Button> : null }
                </div>
            </Alert>
        );
    }

    doubleCheckModal = () => {
        return showConfirmModal({
            title: this.props.t('Confirm'),
            text: this.props.t('Are you sure you want to continue?'),
            okText: this.props.t('Yes, continue'),
            cancelText: this.props.t('Cancel'),
        });
    }

    onSetDefaultClick = (p: IPaymentMethod) => {
        return async () => {
            if (!await this.doubleCheckModal()) {
                return;
            }

            try {
                await setDefaultPaymentMethod({ paymentMethodId: p.id });
                trackUmami('Change Default Payment Method');

                await this.loadPaymentMethods();
            } catch (e) {
                displayErrorNotification(e);
            }
        };
    }

    onRemoveClick = (p: IPaymentMethod) => {
        return async () => {
            if (!await this.doubleCheckModal()) {
                return;
            }

            try {
                await removePaymentMethod(p.id);
                trackUmami('Remove Payment Method');

                await this.loadPaymentMethods();

                toast.success('Successfully removed the payment method ending in ' + p.last4);
            } catch (e) {
                displayErrorNotification(e);
            }
        };
    }

    get paymentMethodList() {
        return this.state.paymentMethods.map((p) => (
            <PageBody.List.Item
                key={p.id}
                thumb={p.type === 'card' ? <AiOutlineCreditCard /> : <AiOutlineBank />}
                title={
                    <React.Fragment>
                        { p.type === 'card' ? p.name : p.bankName }&nbsp;
                        { p.default ? <Badge pill variant="primary">{ this.props.t('Default') }</Badge> : null }
                    </React.Fragment>
                }
                description={
                    <React.Fragment>
                        <span className="capitalize">{ p.brand } **** { p.last4 };&nbsp;</span>
                        { p.type === 'card' ? <span>Exp: { p.expirationMonth } / { p.expirationYear }</span> : null }
                        { p.type === 'bank' || p.type === 'us-bank' ? <span className="capitalize">{ p.bankStatus }</span> : null }
                    </React.Fragment>
                }
                details={
                    <React.Fragment>
                        <Button variant="outline-primary" size="sm" disabled={p.default} onClick={this.onSetDefaultClick(p)}>{ this.props.t('Set Default') }</Button>
                        <Button variant="outline-danger" size="sm" disabled={p.default || this.state.paymentMethods.length === 1} onClick={this.onRemoveClick(p)}>{ this.props.t('Remove') }</Button>
                    </React.Fragment>
                }
            />
        ));
    }

    get headerButtons() {
        if (this.state.loadingInitialMethods) {
            return null;
        }

        const { dashboardData } = this.props;

        if (!dashboardData || dashboardData.org.onlinePaymentsDisabled || !dashboardData.client.canSetupPaymentMethods) {
            return null;
        }

        const buttons: JSX.Element[] = [];

        if (dashboardData.client.allowedPaymentMethods.banks) {
            buttons.push(
                <PageBody.Header.Button
                    key="linkBank"
                    color="blue"
                    icon={this.state.loadingBank ? <Spinner size="sm" animation="border" /> : <AiOutlineBank />}
                    text={this.props.t('Link Bank')}
                    disabled={dashboardData.org.paymentProviderAccount === ''}
                    onClick={this.onLinkBankClick}
                />
            );
        }

        if (dashboardData.client.allowedPaymentMethods.cards) {
            buttons.push(
                <PageBody.Header.Button
                    key="newCard"
                    color="yellow"
                    icon={<AiOutlineCreditCard />}
                    text={this.props.t('New Card')}
                    disabled={dashboardData.org.paymentProviderAccount === ''}
                    onClick={this.openCardModal}
                />
            );
        }

        return buttons;
    }

    get modals() {
        if (!this.props.dashboardData || !this.props.dashboardData.org || this.props.dashboardData.org.onlinePaymentsDisabled) {
            return null;
        }

        if (this.props.dashboardData.org.paymentProvider === 'stripe') {
            return (
                <Suspense fallback={null}>
                    <StripeCardModal visible={this.state.cardModalVisible} onClose={this.closeCardModal} />
                    <PlaidAchModal visible={this.state.achModalVisible} linkToken={this.state.plaidLinkToken} onClose={this.onClosePlaidModal} />
                </Suspense>
            );
        }

        return (
            <Suspense fallback={null}>
                <PayArcCardModal visible={this.state.cardModalVisible} onClose={this.closeCardModal} />
                <PayArcBankModal visible={this.state.achModalVisible} onClose={this.onClosePlaidModal} />
            </Suspense>
        );
    }

    render() {
        return (
            <PageBody bodyContentLg>
                <PageBody.Header
                    leftContent={this.props.t('Payment Methods')}
                    buttons={this.headerButtons}
                />

                {
                    this.props?.dashboardData?.org.paymentProviderAccount === ''
                    ?
                        this.onlinePaymentsNotSetup
                    :
                        <PageBody.List
                            items={this.paymentMethodList}
                            emptyContent={this.noPaymentMethodsContent}
                        />
                }

                { this.modals }
            </PageBody>
        );
    }
}

export const PaymentMethodsView = withTranslation()(connector(PaymentMethodsViewBase));
