import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { UnregisterCallback } from 'history';

interface IUmamiAnalyticsProps extends RouteComponentProps {}

interface IUmamiAnalyticsState {}

class UmamiAnalyticsBase extends React.PureComponent<IUmamiAnalyticsProps, IUmamiAnalyticsState> {
    private unlisten?: UnregisterCallback;

    state: Readonly<IUmamiAnalyticsState> = {};

    componentDidMount() {
        this.unlisten = this.props.history.listen(this.historyListener);

        this.historyListener(this.props.history.location);
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    }

    historyListener = (location: any) => {
        console.log('history listen:', location);

        this.trackView(location.pathname)
    }

    trackView = (pathName: string) => {
        const { umami } = global as any;

        if (typeof umami !== 'function') {
            setTimeout(() => this.trackView(pathName), 1500);
            return;
        }

        umami.trackView(pathName);
    }

    render() {
        return null;
    }
}


export const UmamiAnalytics = withRouter(UmamiAnalyticsBase);
