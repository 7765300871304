import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import type { BrowserHistoryBuildOptions } from 'history';
import { IconContext } from 'react-icons';
import { ToastContainer } from 'react-toastify';
import ModalContainer from 'react-modal-promise';

import { UmamiAnalytics } from 'components/misc/umami';
import { registerUmamiScript } from 'components/misc/registerUmami';

import { store } from 'store';

import { ImpersonatingWarning } from 'components/misc/impersonatingWarning';

import { LayoutForNoAuthorization } from 'layout/noauth';
import { MainLayout } from 'layout/main';

let browserHistoryOptions: BrowserHistoryBuildOptions | undefined = undefined;
if (process.env.REACT_APP_BASEURL?.includes('/proxy/3008')) {
    browserHistoryOptions = { basename: '/proxy/3008' };
}

const history = createBrowserHistory(browserHistoryOptions);

export const AppRouter: React.FC = () => {
    useEffect(() => {
        registerUmamiScript('https://uem.fidetech.io/umami.js', process.env.REACT_APP_UMAMI_WEBSITE_ID!);
    }, []);

    return (
        <Provider store={store}>
            <IconContext.Provider value={{ className: 'react-icons' }}>
                <ImpersonatingWarning />

                <Router history={history}>

                    <Switch>
                        <Redirect exact from="/login" to="/auth/login" />
                        <Redirect exact from="/register" to="/auth/register" />

                        <Route path="/auth" component={LayoutForNoAuthorization} />

                        <MainLayout />
                    </Switch>

                    <UmamiAnalytics />
                </Router>

                <ToastContainer />
                <ModalContainer />
            </IconContext.Provider>
        </Provider>
    );
}
