import React from 'react';

import { PageBodyHeaderButton } from './pageBodyHeaderButton';

interface IPageBodyHeaderProps {
    leftText?: React.ReactNode;
    leftContent?: React.ReactNode;

    buttons?: React.ReactElement<typeof PageBodyHeaderButton>[] | null;
}

interface IPageBodyHeaderState {};

export class PageBodyHeader extends React.PureComponent<IPageBodyHeaderProps, IPageBodyHeaderState> {
    static Button = PageBodyHeaderButton;

    state: Readonly<IPageBodyHeaderState> = {};

    get rightButtons() {
        if (!Array.isArray(this.props.buttons) || this.props.buttons.length === 0) {
            return null;
        }

        return (
            <div className="body-header-item body-header-right">
                { this.props.buttons }
            </div>
        );
    }

    render() {
        return (
            <div className="body-header section-to-header mb-30">
                <div className="body-header-inner">
                    <div className="body-header-item body-header-info">
                        { this.props.leftText ? <p>{ this.props.leftText }</p> : null }
                        { this.props.leftContent ? <h3>{ this.props.leftContent }</h3> : null }
                    </div>

                    { this.rightButtons }
                </div>
            </div>
        );
    }
}
