import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';

import { isImpersonating } from 'utils/auth';

export const ImpersonatingWarning: React.FC = () => {
    const [impersonating, setImpersonating] = useState(false);

    useEffect(() => {
        isImpersonating().then((is) => {
            setImpersonating(is);
        });
    }, []);

    if (!impersonating) {
        return null;
    }

    return (
        <Alert variant="warning" style={{ marginBottom: '0', borderRadius: '0' }}>
            You are currently logged in as the client and taking action on their behalf. Once you close this window (or tab) you will be logged out.
        </Alert>
    );
}
