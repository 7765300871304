import React from 'react';

//#region feature card
interface IPageBodyFeatureCardProps {
    color: 'red' | 'violet' | 'yellow' | 'blue' | 'green';
    thumb: React.ReactNode;
    title: React.ReactNode;
    detail?: React.ReactNode;
}

interface IPageBodyFeatureCardState {}

export class PageBodyFeatureCard extends React.PureComponent<IPageBodyFeatureCardProps, IPageBodyFeatureCardState> {
    state: Readonly<IPageBodyFeatureCardState> = {};

    render() {
        return (
            <div className="col col-sm-6 pb-15">
                <div className={`feature-card feature-card-${ this.props.color }`}>
                    <div className="feature-card-thumb">
                        { this.props.thumb }
                    </div>
                    <div className="feature-card-details">
                        <p>{ this.props.title }</p>
                        <h3>{ this.props.detail }</h3>
                    </div>
                </div>
            </div>
        );
    }
}
//#endregion feature card

interface IPageBodyFeatureProps {}

interface IPageBodyFeatureState {}

export class PageBodyFeature extends React.PureComponent<IPageBodyFeatureProps, IPageBodyFeatureState> {
    static Card = PageBodyFeatureCard;

    state: Readonly<IPageBodyFeatureState> = {};

    render() {
        return (
            <div className="feature-section mb-15">
                <div className="row gx-3">
                    { this.props.children }
                </div>
            </div>
        );
    }
}
