import React from 'react';
import Loader from 'react-loader-spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { PageBodyHeader } from './pageBodyHeader';
import { PageBodyFeature } from './pageBodyFeature';
import { PageBodyList } from './pageBodyList';

interface IPageBodyProps {
    header?: React.ReactNode;
    bodyContentLg?: boolean;
    loading?: boolean;
}

interface IPageBodyState { }

export class PageBody extends React.PureComponent<IPageBodyProps, IPageBodyState> {
    static Header = PageBodyHeader;
    static Feature = PageBodyFeature;
    static List = PageBodyList;

    state: Readonly<IPageBodyState> = {};

    get header() {
        if (!this.props.header) {
            return null;
        }

        return (
            <div className="page-header">
                <div className="page-header-title page-header-item">
                    <h3>{ this.props.header }</h3>
                </div>
            </div>
        );
    }

    render() {
        if (this.props.loading) {
            return (
                <div className="loader-overlay">
                    <Loader type="Oval" color="#3646cd" />
                </div>
            );
        }

        return (
            <div className={ `body-content ${ this.props.bodyContentLg ? 'body-content-lg' : ''}`}>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col lg="10">
                            { this.header }

                            { this.props.children }
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
