import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources: Resource = {
    en: {
        translation: {
            "Sign In": "Sign In",
            "Sign In To View Your Account Details": "Sign In To View Your Account Details",
            "Account Number": "Account Number",
            "Enter your account number": "Enter your account number",
            "Invalid Account Number": "Uh oh! Invalid Account Number!",
            "Invalid Account Number Details": "We could not find an account by the account number you provided. Please double check and try again. Otherwise, contact your account manager to validate your account information.",
            "Auth Code": "Auth Code",
            "Get Code": "Get Code",
            "Didnt get the code?": "Didn't get the code?",
            "Remember Me!": "Remember Me!",
            "Logout": "Logout",
            "You have successfully logged out.": "You have successfully logged out.",
            "Logging out": "Logging out",
            "logout of Lendiom Pay": "logout of Lendiom Pay",
            "Error 404: Page Not found": "Error 404: Page Not found",
            "The page you were looking for could not be found.": "The page you are looking for could not be found.",
            "Go to Homepage": "Go to Homepage",
            "Loans": "Loans",
            "You currently have no loans.": "You currently have no loans.",
            "Balance Due": "Balance Due",
            "No Payment Methods": "No Payment Methods",
            "Payment Methods": "Payment Methods",
            "View and manage the payment methods you have connected.": "View and manage the payment methods you have connected.",
            "Next Due Date": "Next Due Date",
            "Last Payment Date": "Last Payment Date",
            "Property Tax Due": "Property Tax Due",
            "View Details": "View Details",
            "Account Not Setup": "Account Not Setup",
            "Account Not Setup Details": "Adding payment methods requires an email address and a physical mailing address. Your account does not have one of these. Please contact <strong>{{ orgName }}</strong> to provide them this information.",
            "No payment methods details bankd and card support": "You have yet to connect any payment methods. If you would like to setup paying online or setup automatic payments, please connect either a Bank Account or a Credit/Debit card.",
            "No payment methods details only bank support": "You have yet to connect any payment methods. If you would like to setup paying online or setup automatic payments, please connect a Bank Account.",
            "Link Bank": "Link Bank",
            "New Card": "New Card",
            "Link a Bank Account": "Link a Bank Account",
            "Link Bank Account Authorization Details": "Lendiom Pay utilizes <1>Plaid</1> to securely access your bank account information for quick and seemless linking. By continuing, you authorize Lendiom Pay to securely collect information provided from Plaid and authorize withdraws.",
            "Connect My Bank": "Connect My Bank",
            "Link a Credit/Debit Card": "Link a Credit/Debit Card",
            "Name on Card": "Name on Card",
            "Enter the name on the card": "Enter the name on the card",
            "Name on the card must be provided otherwise we cant add the card.": "Name on the card must be provided otherwise we can not add the card.",
            "Credit or Debit Card": "Credit or Debit Card",
            "This information is handled by our provider and not stored on any of our servers.": "This information is handled by our provider and not stored on any of our servers.",
            "Confirm": "Confirm",
            "Cancel": "Cancel",
            "Yes, continue": "Yes, continue",
            "Are you sure you want to continue?": "Are you sure you want to continue?",
            "Default": "Default",
            "Set Default": "Set Default",
            "Remove": "Remove",
            "Save": "Save",
            "Your account name": "Your account name",
            "Menu": "Menu",
            "Home": "Home",
            "Help": "Help",
            "link to manage payment methods": "link to manage payment methods",
            "help pages & documentation": "help pages & documentation",
            "Online Payments Not Set Up": "Online Payments Not Set Up",
            "Online Payments Not Set Up Paragraph": "Online payments are not enabled. Please contact <strong>{{ orgName }}</strong> to make a payment."
        },
    },
    es: {
        translation: {
            "Sign In": "Ingresar",
            "Sign In To View Your Account Details": "Ingresar para ver los detalles de su cuenta",
            "Account Number": "Numero de Cuenta",
            "Enter your account number": "Ingresar numero de cuenta",
            "Invalid Account Number": "¡Lo sentimos, el número de cuenta es invalido!",
            "Invalid Account Number Details": "No encontramos una cuenta con ese numero. Por favor rectifique e intente de nuevo. Si no puede ingresar, póngase en contacto con el administrador de su cuenta para validar su información de cuenta",
            "Auth Code": "Código de Autenticación",
            "Get Code": "Obtener Código",
            "Didnt get the code?": "No ha recibido su código?",
            "Remember Me!": "¡Acuérdate de mí!",
            "Logout": "Cerrar sesión",
            "You have successfully logged out.": "La sesión ha sido cerrada exitosamente",
            "Logging out": "Cerrando sesión",
            "logout of Lendiom Pay": "Cerrar sesión de Lendiom Pay",
            "Error 404: Page Not found": "Error 404: Pagina no hallada",
            "The page you were looking for could not be found.": "La pagina que está buscando no se ha hallado",
            "Go to Homepage": "Ir a la pagina de inicio",
            "Loans": "Prestamos",
            "You currently have no loans.": "No tiene prestamos activos",
            "Balance Due": "Saldo a pagar",
            "No Payment Methods": "No hay métodos de pago",
            "Payment Methods": "Métodos de pago",
            "View and manage the payment methods you have connected.": "Ver y administrar los métodos de pago que ha conectado",
            "Next Due Date": "Siguiente Pago",
            "Last Payment Date": "Último Pago Realizado",
            "Property Tax Due": "Impuestos de propiedad a pagar",
            "View Details": "Ver Detalles",
            "Account Not Setup": "Cuenta no establecida",
            "Account Not Setup Details": "Para agregar métodos de pago es necesario incluir correo electrónico y domicilio. Su cuenta no contiene al menos uno de estos requisitos. Favor de contactar a <strong>{{ orgName }}</strong> para proveer esta información.",
            "No payment methods details bankd and card support": "Aun no ha conectado ningún método de pago. Si quiere establecer pagos en linea o establecer pagos automáticos, favor de conectar cuenta bancaria o tarjeta de crédito/débito.",
            "No payment methods details only bank support": "Aun no ha conectado ningún método de pago. Si quiere establecer pagos en linea, o establecer pagos automáticos, favor de conectar cuenta bancaria.",
            "Link Bank": "Vincular banco",
            "New Card": "Nueva tarjeta",
            "Link a Bank Account": "Vincular cuenta bancaria",
            "Link Bank Account Authorization Details": "Lendiom Pay usa <1>Plaid</1> para tener acceso seguro a su información de cuenta bancaria para vincularla de forma fácil y rápida. Al seleccionar continuar, usted autoriza a Lendiom Pay para recibir información de forma segura desde Plaid y autoriza pagos.",
            "Connect My Bank": "Conectar Mi Banco",
            "Link a Credit/Debit Card": "Vincular una tarjeta de Crédito/Débito",
            "Name on Card": "Nombre en la tarjeta",
            "Enter the name on the card": "Ingrese el nombre como aparece en la tarjeta",
            "Name on the card must be provided otherwise we cant add the card.": "El nombre que aparece en la tarjeta debe ser ingresado, o la tarjeta no podra ser agregada.",
            "Credit or Debit Card": "Tarjeta de Débito o Crédito",
            "This information is handled by our provider and not stored on any of our servers.": "Esta información es procesada por nuestro proveedor, y no es almacenada en ninguno de los servidores de informática.",
            "Confirm": "Confirmar",
            "Cancel": "Cancelar",
            "Yes, continue": "Si, continuar",
            "Are you sure you want to continue?": "Esta seguro de querer continuar",
            "Default": "Auto-configuración",
            "Set Default": "Configurar auto-configuración",
            "Remove": "Remover",
            "Save": "Ahorrar",
            "Your account name": "Nombre de cuenta",
            "Menu": "Menu",
            "Home": "Inicio",
            "Help": "Ayuda",
            "link to manage payment methods": "enlace para administrar métodos de pago",
            "help pages & documentation": "paginas de ayuda y documentación",
            "Online Payments Not Set Up": "Pagos en línea no configurados",
            "Online Payments Not Set Up Paragraph": "Los pagos en línea no están habilitados. Comuníquese con <strong>{{ orgName }}</strong> para realizar un pago."
        },
    },
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
