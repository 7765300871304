import React from 'react';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';

import { MyTooltip } from 'utils/tooltips';
import { PageBodyHeaderDropdownButton } from './pageBodyHeaderDropdown';

interface IPageBodyHeaderRightButtonProps {
    color?: 'violet' | 'yellow' | 'blue' | 'red';
    icon?: React.ReactNode;
    text: React.ReactNode;
    tooltip?: React.ReactNode;
    asDropdown?: boolean;
    dropDownItems?: React.ReactNode[] | null;
    disabled?: boolean;
    onClick?: () => void;
}

export const PageBodyHeaderButton: React.FC<IPageBodyHeaderRightButtonProps> = (props) => {
    function onButtonClick() {
        if (props.disabled) {
            return;
        }

        if (typeof props.onClick === 'function') {
            props.onClick();
        } else {
            throw new Error('Invalid configuration of the button');
        }
    };

    const text = () => {
        const t = (
            <p className={props.disabled ? 'disabled-cursor' : 'pointer'}>{ props.text }</p>
        );

        if (!props.tooltip) {
            return t;
        }

        return (
            <MyTooltip placement="bottom" tooltip={ props.tooltip } children={t} />
        );
    };

    if (props.asDropdown) {
        if (!Array.isArray(props.dropDownItems) || props.dropDownItems.filter((i) => !!i).length === 0) {
            return null;
        }

        return (
            <Dropdown>
                <Dropdown.Toggle as={PageBodyHeaderDropdownButton}>{ props.text }</Dropdown.Toggle>

                <Dropdown.Menu>
                    { props.dropDownItems.filter((i) => i !== null) }
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    return (
        <div className={`color-${ props.color } ${ props.disabled ? 'disabled disabled-cursor' : '' }`} onClick={onButtonClick}>
            <Button variant="link" disabled={props.disabled}>{ props.icon }</Button>
            { text() }
        </div>
    );
}
