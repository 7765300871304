import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalState } from 'store';

export type StripeState = {
    isLoaded: boolean;
};

const initialStripeState: StripeState = {
    isLoaded: false,
};

export const stripeSlice = createSlice({
    name: 'stripe',
    initialState: initialStripeState,
    reducers: {
        loaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
    },
});

export const stripeActions = stripeSlice.actions;

export const isStripeLoaded = (state: GlobalState) => state.stripe.isLoaded;

export default stripeSlice.reducer;
