import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { revokeToken } from 'utils/auth';

export const LogoutView: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();

    revokeToken();
    toast.success(t('You have successfully logged out.'));

    setTimeout(() => history.push('/auth/login'), 250);

    return (
        <h1>{ t('Logging out') }</h1>
    );
}
