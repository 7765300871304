import React from 'react';

interface IAppBarProps {
    title: string;
    showBackArrow: boolean;
    backUrl?: string;
}

interface IAppBarState {}

export class AppBar extends React.PureComponent<IAppBarProps, IAppBarState> {
    state: Readonly<IAppBarState> = {};

    get appBarActions() {
        if (!this.props.showBackArrow) {
            return null;
        }

        return (
            <div className="appbar-item appbar-actions">
                <div className="appbar-action-item">
                    <a href={this.props.backUrl} className="back-page"><i className="flaticon-left-arrow"></i></a>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="fixed-top">
                <div className="appbar-area sticky-black">
                    <div className="container">
                        <div className="appbar-container">
                            { this.appBarActions }
                            <div className="appbar-item appbar-page-title mx-auto">
                                <h3>{ this.props.title }</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
