import decoder from 'jwt-decode';
import querystring from 'querystring';
import { Mutex } from 'async-mutex';

import { JwtToken, IAuthResult } from 'models/authPayloads';

const getMutex = new Mutex();

export const stripeApiKey = `${process.env.REACT_APP_STRIPE_KEY}`;

//#region jwt token items
export const jwtTokenKey = 'LendiomClientToken';
export const jwtExpiresAtKey = 'LendiomClientTokenExpiresAt';
export const impersonatedKey = 'LendiomClientImpersonated';
export const accountKey = 'LendiomClientAccountKey';

export async function getJwtToken(): Promise<string> {
    return await getMutex.runExclusive(() => {
        let token = window.sessionStorage.getItem(jwtTokenKey);
        let expiresAtString = window.sessionStorage.getItem(jwtExpiresAtKey);
        if (window.localStorage.getItem(jwtTokenKey)) {
            token = window.localStorage.getItem(jwtTokenKey);
            expiresAtString = window.localStorage.getItem(jwtExpiresAtKey)
        }

        if (!token || !expiresAtString) {
            //TODO: switch over to URLSearchParams

            const parsed = querystring.parse(window.location.search.replace('?', ''));
            if (!parsed || !parsed.accountnumber || !parsed.token || Array.isArray(parsed.token)) {
                return '';
            }

            const newExpiresAt = storeToken(parsed.token as string, false, parsed.impersonated === 'true', parsed.accountnumber as string);
            if (newExpiresAt.length === 0) {
                return '';
            }

            token = parsed.token;
            expiresAtString = newExpiresAt;
            window.location.search = '';
        }

        const expiresAt = new Date(JSON.parse(expiresAtString));
        if (new Date().getTime() < expiresAt.getTime()) {
            return token;
        } else {
            console.log('auth token has expired');
            revokeToken();
        }

        return '';
    });
}

export async function getBearerToken(): Promise<{ Authorization: string }> {
    const token = await getJwtToken();
    if (!token) {
        return {
            Authorization: '',
        };
    }

    return {
        Authorization: `Bearer ${ token }`,
    };
}

export async function isImpersonating(): Promise<boolean> {
    const token = await getJwtToken();
    if (!token) {
        return false;
    }

    let value: string | null = '';
    if (window.localStorage.getItem(impersonatedKey)) {
        value = window.localStorage.getItem(impersonatedKey);
    } else if (window.sessionStorage.getItem(impersonatedKey)) {
        value = window.sessionStorage.getItem(impersonatedKey);
    } else {
        return false;
    }

    return value === 'true';
}

export function getAccountToken(): string | null {
    return window.localStorage.getItem(accountKey);
}

export function storeToken(token: string, permanent: boolean, impersonated: boolean, accountNumber: string): string {
    const jwt = decoder<JwtToken>(token);

    const expiresAt = new Date(jwt.exp * 1000);
    if (new Date().getTime() > expiresAt.getTime()) {
        console.log('the token to store has expired?!!');
        return '';
    }

    if (permanent) {
        window.localStorage.setItem(jwtTokenKey, token);
        window.localStorage.setItem(jwtExpiresAtKey, JSON.stringify(expiresAt));
        window.localStorage.setItem(impersonatedKey, `false`);
        window.localStorage.setItem(accountKey, accountNumber);
    } else {
        window.sessionStorage.setItem(jwtTokenKey, token);
        window.sessionStorage.setItem(jwtExpiresAtKey, JSON.stringify(expiresAt));
        window.sessionStorage.setItem(impersonatedKey, `${ impersonated }`);

        if (!impersonated) {
            window.localStorage.setItem(accountKey, accountNumber);
        }
    }

    return JSON.stringify(expiresAt);
}

export function revokeToken(): void {
    window.localStorage.removeItem(jwtTokenKey);
    window.localStorage.removeItem(jwtExpiresAtKey);
    window.localStorage.removeItem(impersonatedKey);

    window.sessionStorage.removeItem(jwtTokenKey);
    window.sessionStorage.removeItem(jwtExpiresAtKey);
    window.sessionStorage.removeItem(impersonatedKey);
}

export function storeTokenFromResult(result: IAuthResult, rememberThem: boolean, accountNumber: string) {
    storeToken(result.accessToken, rememberThem, false, accountNumber);
}

export async function hasValidToken(): Promise<boolean> {
    return !!await getJwtToken();
}
//#endregion
