import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { createModal } from 'react-modal-promise';

interface IConfirmModalProps {
    isOpen: boolean;
    onResolve: (result: boolean) => void;
    text: React.ReactNode;
    title: React.ReactNode;
    okText: React.ReactNode;
    cancelText: React.ReactNode;
}

const ConfirmModalBase = (props: IConfirmModalProps) => {
    const ok = () => props.onResolve(true);
    const cancel = () => props.onResolve(false);

    return (
        <Modal show={props.isOpen} backdrop="static" bsPrefix="top-most modal">
            <Modal.Header onHide={cancel}>{ props.title }</Modal.Header>
            <Modal.Body>{ props.text }</Modal.Body>
            <Modal.Footer>
                <Button variant="outline-success" onClick={ok}>{ props.okText }</Button>
                <Button variant="outline-danger" onClick={cancel}>{ props.cancelText }</Button>
            </Modal.Footer>
        </Modal>
    );
};

export const showConfirmModal = createModal<IConfirmModalProps, boolean>(ConfirmModalBase);
