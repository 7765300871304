import { del, get, post } from './index';
import { ICreateBankPaymentMethodPayload, ICreateCardPaymentMethodPayload, ILinkAchViaPlaidPayload, IPaymentMethod, ISetDefaultPaymentMethodPayload } from 'models/billing';

export const getPaymentMethods = () => get<IPaymentMethod[]>(`/payment-methods`, true);
export const getPaymentIntentSecret = () => get<{ secret: string }>(`/payment-methods/card/start`, true);
export const setDefaultPaymentMethod = (body: ISetDefaultPaymentMethodPayload) => post<ISetDefaultPaymentMethodPayload, void>(`/payment-methods/card/set-default`, body, true);
export const removePaymentMethod = (paymentMethodId: string) => del(`/payment-methods/card/${ paymentMethodId }`, true);
export const createPaymentMethodCard = (payload: ICreateCardPaymentMethodPayload) => post<ICreateCardPaymentMethodPayload, void>(`/payment-methods/card`, payload, true);

export const createPaymentMethodBank = (payload: ICreateBankPaymentMethodPayload) => post<ICreateBankPaymentMethodPayload, void>(`/payment-methods/ach`, payload, true);
export const getPlaidLinkToken = () => get<{ linkToken: string }>(`/payment-methods/ach/start`, true);
export const finishPlaidAchLinkage = (publicToken: string, bankAccountID: string) => post<ILinkAchViaPlaidPayload, void>(`/payment-methods/ach/finish`, { publicToken, bankAccountID }, true);
