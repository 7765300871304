import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { AiOutlineIdcard, AiOutlineKey } from 'react-icons/ai';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

import { AppBar } from 'layout/components/appBar';
import { PageBody } from 'layout/components/pageBody';

import { requestAuthCode, submitAuthCode } from 'api/auth';
import { getAccountToken, storeTokenFromResult } from 'utils/auth';
import { displayErrorNotification } from 'utils/errors';
import { ErrorResponse } from 'models/errors/errorResponse';

interface ILoginProps extends RouteComponentProps, WithTranslation { }

interface ILoginState {
    isSubmitting: boolean;
    accountNumberSubmitted: boolean;
    invalidAccountNumber: boolean;
    accountNumber: string;
    code: string;
    rememberMe: boolean;
}

class LoginBase extends React.PureComponent<ILoginProps, ILoginState> {
    state: Readonly<ILoginState> = {
        isSubmitting: false,
        accountNumberSubmitted: false,
        invalidAccountNumber: false,
        accountNumber: '',
        code: '',
        rememberMe: true,
    };

    componentDidMount() {
        const accountNumber = getAccountToken();
        if (typeof accountNumber === 'string') {
            this.setState({ accountNumber });
        }
    }

    //TODO: if they have not entered the code after 30~60 seconds, show the "didn't get the code?"
    //TODO: if they have "remember me" enabled, save their account number and pull it the next time the page loads

    onRequestAuthCodeClick = async (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        if (!this.state.accountNumber || !this.state.accountNumber.trim()) {
            return;
        }

        this.setState({ isSubmitting: true });

        try {
            await requestAuthCode({ accountNumber: this.state.accountNumber.trim() });
        } catch (e) {
            if (e instanceof ErrorResponse && e.status !== 404) {
                displayErrorNotification(e);
            }

            this.setState({ invalidAccountNumber: true, accountNumberSubmitted: false, isSubmitting: false });
            return;
        }

        this.setState({ accountNumberSubmitted: true, isSubmitting: false, invalidAccountNumber: false });
    }

    onSubmitAuthCodeClick = async (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        if (!this.state.accountNumber || !this.state.code) {
            return;
        }

        this.setState({ isSubmitting: true });

        try {
            const result = await submitAuthCode({ accountNumber: this.state.accountNumber, authCode: this.state.code, extended: this.state.rememberMe });
            storeTokenFromResult(result, this.state.rememberMe, this.state.accountNumber);

            this.props.history.push('/');
        } catch (e) {
            if (e instanceof ErrorResponse && e.status !== 404) {
                displayErrorNotification(e);
            }

            this.setState({ invalidAccountNumber: true, accountNumberSubmitted: false, isSubmitting: false });
            return;
        }
    }

    onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.name) {
            case 'token':
                this.setState({ code: event.target.value });
                break;
            case 'username':
                this.setState({ accountNumber: event.target.value });
                break;
            case 'rememberMe':
                this.setState({ rememberMe: event.target.checked });
                break;
        }
    }

    onAlertDismiss = () => {
        this.setState({ invalidAccountNumber: false });
    }

    render() {
        return (
            <React.Fragment>
                <AppBar title={this.props.t('Sign In')} showBackArrow={false} />

                <PageBody header={this.props.t('Sign In To View Your Account Details')}>
                    <div className="authentication-form pb-15">
                        <Form>
                            <Collapse in={this.state.invalidAccountNumber}>
                                <div>
                                    <Alert variant="danger" dismissible onClose={this.onAlertDismiss}>
                                        <Alert.Heading>{ this.props.t('Invalid Account Number') }</Alert.Heading>
                                        <p>{ this.props.t('Invalid Account Number Details') }</p>
                                    </Alert>
                                </div>
                            </Collapse>

                            <Form.Group controlId="username">
                                <Form.Label>{ this.props.t('Account Number') }</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        name="username"
                                        type="text"
                                        placeholder={this.props.t('Enter your account number')}
                                        value={this.state.accountNumber}
                                        onChange={this.onValueChange}
                                        disabled={this.state.accountNumberSubmitted || this.state.isSubmitting}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text><AiOutlineIdcard /></InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>

                            <Collapse in={this.state.accountNumberSubmitted}>
                                <div>
                                    <Form.Group controlId="token">
                                        <Form.Label>{ this.props.t('Auth Code') }</Form.Label>

                                        <InputGroup>
                                            <Form.Control
                                                name="token"
                                                type="text"
                                                maxLength={6}
                                                placeholder="123456"
                                                inputMode="numeric"
                                                pattern="[0-9]*"
                                                autoComplete="one-time-code"
                                                value={this.state.code}
                                                onChange={this.onValueChange}
                                                disabled={this.state.isSubmitting}
                                            />

                                            <InputGroup.Append>
                                                <InputGroup.Text><AiOutlineKey /></InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>

                                    <div className="authentication-account-access pb-15">
                                        <div className="authentication-account-access-item">
                                            <Form.Check
                                                name="rememberMe"
                                                type="checkbox"
                                                label={this.props.t('Remember Me!')}
                                                checked={this.state.rememberMe}
                                                onChange={this.onValueChange}
                                                disabled={this.state.isSubmitting}
                                            />
                                        </div>
                                        <div className="authentication-account-access-item">
                                            <div className="authentication-link">
                                                <Button
                                                    variant="link"
                                                    onClick={this.onRequestAuthCodeClick}
                                                    disabled={this.state.isSubmitting}
                                                >{ this.props.t('Didnt get the code?') }</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>

                            <Form.Row>
                                {this.state.accountNumberSubmitted
                                    ?
                                    <Col sm="6" md="6" lg="2" xl="2">
                                        <Button
                                            variant="primary"
                                            className="full-width mb-10"
                                            onClick={this.onSubmitAuthCodeClick}
                                            disabled={this.state.code === '' || this.state.isSubmitting}
                                        >
                                            {this.state.isSubmitting ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
                                            <span>&nbsp;{ this.props.t('Sign In') }</span>
                                        </Button>
                                    </Col>
                                    :
                                    <Col sm="6" md="6" lg="2" xl="2">
                                        <Button
                                            variant="primary"
                                            className="full-width mb-10"
                                            onClick={this.onRequestAuthCodeClick}
                                            disabled={this.state.accountNumber === '' || this.state.isSubmitting}
                                        >
                                            {this.state.isSubmitting ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
                                            <span>&nbsp;{ this.props.t('Get Code') }</span>
                                        </Button>
                                    </Col>
                                }
                            </Form.Row>
                        </Form>
                    </div>
                </PageBody>
            </React.Fragment>
        );
    }
}

export const LoginView = withTranslation()(withRouter(LoginBase));
