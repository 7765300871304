import React from 'react';
import currency from 'currency.js';

interface ISimpleCurrencyProps {
    amount: string | currency;
    negative?: boolean;
    onClick?: () => void;
}

export const SimpleCurrency: React.FunctionComponent<ISimpleCurrencyProps> = (props: ISimpleCurrencyProps) => {
    if (!props.amount) {
        return (
            <span>-</span>
        );
    }

    const className = typeof props.onClick === 'function' ? 'clickable' : undefined;
    const amt = currency(props.amount, { precision: 2 });
    const formattedAmount = amt.format(true);

    if (props.negative || amt.intValue < 0) {
        if (amt.intValue < 0) {
            return (
                <span className={className} onClick={props.onClick}>({ amt.multiply(-1).format(true) })</span>
            );
        }

        return (
            <span className={className} onClick={props.onClick}>({ formattedAmount })</span>
        );
    }

    return (
        <span className={className} onClick={props.onClick}>{ formattedAmount }</span>
    );
}

export const isNegative = (value: string): boolean => {
    if (!value) {
        return false;
    }

    try {
        return 0 > parseFloat(value);
    } catch {
        return false;
    }
}

export const isZero = (value: string): boolean => {
    if (!value) {
        return true;
    }

    try {
        return 0 === parseFloat(value);
    } catch {
        return true;
    }
}

export function sanitizeCurrency(amount: string, allowNegative?: boolean): string {
    if (!amount) {
        return '0';
    }

    if (allowNegative) {
        return `${ amount }`.replace(/[^-0-9.]/g, '');
    }

    return `${ amount }`.replace(/[^0-9.]/g, '');
}

export const PhoneNumber: React.FC<{ value?: string, asLink?: boolean; }> = React.memo(({ value, asLink }) => {
    if (!value) {
        return null;
    }

    const formatted = formatPhoneNumberForDisplay(value);

    if (asLink) {
        const linkFormat = formatPhoneNumberForLink(value);

        return (
            <a href={`tel:${linkFormat}`}>
                <span>
                    {formatted}
                </span>
            </a>
        );
    }

    return (
        <span>{formatted}</span>
    );
});

export function formatPhoneNumberForDisplay(phoneNumberString: string): string {
    const cleaned = phoneNumberString.replace('+1', '').replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumberString;
}

export function formatPhoneNumberForLink(phoneNumberString: string): string {
    const cleaned = phoneNumberString.replace('+1', '').replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return `+1${match[1]}${match[2]}${match[3]}`;
    }

    return phoneNumberString;
}
