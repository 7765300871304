import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { useAppDispatch, useAppSelector } from 'hooks';
import { fetchDashboardDataAsync, selectData } from 'store/slices/dashboard';
import { stripeApiKey } from 'utils/auth';
import { stripeActions } from 'store/slices/stripe';

interface IStripeLoaderProps {
}

let stripe: ReturnType<typeof loadStripe>;

export const StripeLoader: React.FC<IStripeLoaderProps> = (props) => {
    const [isLoaded, setLoaded] = useState(false);
    const data = useAppSelector(selectData);
    const dispatch = useAppDispatch();

    if (!data) {
        dispatch(fetchDashboardDataAsync());
    }

    useEffect(() => {
        if (!data || !data.org || data.org.onlinePaymentsDisabled || data.org.paymentProvider !== 'stripe' || !data.org.paymentProviderAccount) {
            setLoaded(false);
            return;
        }

        if (isLoaded) {
            return;
        }

        console.log('loading stripe...');

        stripe = loadStripe(stripeApiKey, { stripeAccount: data.org.paymentProviderAccount, locale: 'en' });
        setLoaded(true);

        console.log('...stripe loaded');
    }, [data, data?.org, data?.org.paymentProviderAccount, isLoaded]);

    useEffect(() => {
        if (!isLoaded) {
            return;
        }

        dispatch(stripeActions.loaded(isLoaded));
    }, [dispatch, isLoaded]);

    if (data && data.org && data.org.paymentProviderAccount && isLoaded) {
        return (
            <Elements stripe={stripe}>
                { props.children }
            </Elements>
        );
    }

    return (
        <React.Fragment>{ props.children }</React.Fragment>
    );
};
