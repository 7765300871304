import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PageBody } from 'layout/components/pageBody';
import NotFound from 'images/404.png';

export const NotFoundView: React.FC = React.memo(() => {
    const { t } = useTranslation();

    return (
        <PageBody>
            <section className="error-page-section pb-15">
                <div className="container">
                    <div className="error-page-content">
                        <img src={NotFound} alt="404" />
                        <h2>{ t('Error 404: Page Not found') }</h2>
                        <p>{ t('The page you were looking for could not be found.') }</p>
                        <Link to="/" className="btn main-btn">{ t('Go to Homepage') }</Link>
                    </div>
                </div>
            </section>
        </PageBody>
    );
});

