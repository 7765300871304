import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

interface ITooltipProps {
    placement: 'top' | 'right' | 'bottom' | 'left';
    underlined?: boolean;
    tooltip: React.ReactNode;
}

export const MyTooltip: React.FC<ITooltipProps> = (props) => {
    const overlay = (
        <Tooltip id={`tooltip-${ props.placement }`}>{ props.tooltip }</Tooltip>
    );

    let children = props.children as React.ReactElement;
    if (children && props.underlined) {
        children = (
            <div className="underline-dashed" style={{ display: 'inline', position: 'relative' }}>{ children }</div>
        );
    }

    return (
        <OverlayTrigger placement={props.placement} overlay={overlay} children={children} />
    );
}
