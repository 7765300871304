import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import dashboardReducer from './slices/dashboard';
import stripeReducer from './slices/stripe';
import paymentMethodReducer from './slices/paymentMethods';

const logger = createLogger({ collapsed: true });

export const store = configureStore({
    reducer: {
        dashboard: dashboardReducer,
        stripe: stripeReducer,
        paymentMethods: paymentMethodReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export type AppDispatch = typeof store.dispatch;
export type GlobalState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, GlobalState, unknown, Action<string>>;
