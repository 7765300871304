export class ErrorResponse extends Error {
    constructor(public status: number, public message: string) {
        super(message ? message : `an error occurred making a request to our servers (status code: ${ status })`);
    }
}

export interface IErrorResponse {
    code: number;
    error: string;
    requestId: string;
    status: number;
}

export class ErrorMessageResponse extends ErrorResponse {
    constructor(public error: IErrorResponse, public status: number) {
        super(status, `${ error.error } (${ error.code })`);
    }
}
